import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "className", "href", "replace"],
  _excluded2 = ["activeClassName", "children", "className", "to", "replace", "partialActivePaths", "isActivePathExact"],
  _excluded3 = ["activeClassName", "children", "className", "to", "replace", "partialActivePaths", "isActivePathExact"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import UIButton from '@/components/ui/button';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var useIsActivePath = function useIsActivePath(_ref) {
  var to = _ref.to,
    _ref$partialActivePat = _ref.partialActivePaths,
    partialActivePaths = _ref$partialActivePat === void 0 ? [] : _ref$partialActivePat,
    isActivePathExact = _ref.isActivePathExact;
  var _useRouter = useRouter(),
    asPath = _useRouter.asPath,
    query = _useRouter.query;
  var pathIncludes = function pathIncludes(path) {
    return (path === null || path === void 0 ? void 0 : path.includes(typeof to === 'string' ? to : to.pathname || '')) || (partialActivePaths === null || partialActivePaths === void 0 ? void 0 : partialActivePaths.some(function (p) {
      return path === null || path === void 0 ? void 0 : path.includes(p);
    }));
  };
  if (query.backUrl) {
    if (Array.isArray(query.backUrl)) {
      var lastBackUrl = query.backUrl[query.backUrl.length - 1];
      return pathIncludes(lastBackUrl);
    }
    return pathIncludes(query.backUrl);
  }
  if (isActivePathExact) return asPath === to || partialActivePaths.some(function (p) {
    return asPath === p;
  });
  return asPath.includes(typeof to === 'string' ? to : to.pathname || '') || (partialActivePaths === null || partialActivePaths === void 0 ? void 0 : partialActivePaths.some(function (p) {
    return asPath.includes(p);
  }));
};
export var Link = function Link(_ref2) {
  var children = _ref2.children,
    className = _ref2.className,
    href = _ref2.href,
    replace = _ref2.replace,
    rest = _objectWithoutProperties(_ref2, _excluded);
  return _jsx(NextLink, _objectSpread(_objectSpread({
    href: href,
    className: className
  }, rest), {}, {
    replace: replace,
    children: children
  }));
};
export var NavLink = function NavLink(_ref3) {
  var activeClassName = _ref3.activeClassName,
    children = _ref3.children,
    className = _ref3.className,
    to = _ref3.to,
    replace = _ref3.replace,
    partialActivePaths = _ref3.partialActivePaths,
    isActivePathExact = _ref3.isActivePathExact,
    rest = _objectWithoutProperties(_ref3, _excluded2);
  var isActive = useIsActivePath({
    isActivePathExact: isActivePathExact,
    to: to,
    partialActivePaths: partialActivePaths
  });
  return _jsx(NextLink, _objectSpread(_objectSpread({
    className: isActive ? "".concat(className, " ").concat(activeClassName) : className,
    href: to,
    replace: replace
  }, rest), {}, {
    children: children
  }));
};
export var NavLinkButton = function NavLinkButton(_ref4) {
  var activeClassName = _ref4.activeClassName,
    children = _ref4.children,
    className = _ref4.className,
    to = _ref4.to,
    replace = _ref4.replace,
    partialActivePaths = _ref4.partialActivePaths,
    isActivePathExact = _ref4.isActivePathExact,
    rest = _objectWithoutProperties(_ref4, _excluded3);
  var isActive = useIsActivePath({
    isActivePathExact: isActivePathExact,
    to: to,
    partialActivePaths: partialActivePaths
  });
  var router = useRouter();
  return _jsx(UIButton, _objectSpread(_objectSpread({
    className: isActive ? "".concat(className, " ").concat(activeClassName) : className,
    onClick: function onClick() {
      if (replace) {
        router.replace(to);
        return;
      }
      router.push(to);
    }
  }, rest), {}, {
    children: children
  }));
};
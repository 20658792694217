import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React from 'react';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { isString } from '@/lib/string-utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var getMessages = function getMessages(descriptors, intl) {
  return descriptors.map(function (descriptor) {
    var id = isString(descriptor) ? descriptor : descriptor.id;
    var values = isString(descriptor) ? undefined : descriptor.values;
    return intl.formatMessage({
      id: id
    }, values);
  });
};
var getDocumentTitle = function getDocumentTitle(descriptors, intl) {
  var messages = getMessages(descriptors, intl);
  return "".concat(messages.join(' — '), " | Motimate");
};
var _ref2 = true ? {
  name: "yrqkhi",
  styles: "position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0, 0, 0, 0);white-space:nowrap;border-width:0"
} : {
  name: "1s4kjfr-AppDocumentTitle",
  styles: "position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0, 0, 0, 0);white-space:nowrap;border-width:0;label:AppDocumentTitle;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var AppDocumentTitle = function AppDocumentTitle(_ref) {
  var descriptors = _ref.descriptors;
  var intl = useIntl();
  var _getMessages = getMessages(descriptors, intl),
    _getMessages2 = _slicedToArray(_getMessages, 1),
    h1 = _getMessages2[0];
  return _jsxs(_Fragment, {
    children: [_jsx(Head, {
      children: _jsx("title", {
        children: getDocumentTitle(descriptors, intl)
      })
    }), _jsx("h1", {
      css: _ref2,
      children: h1
    })]
  });
};
export default AppDocumentTitle;